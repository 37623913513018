import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// API
import { login } from './authAPI'

// get user from local storage
const user = JSON.parse(localStorage.getItem('user'))

// initial state
const initialState = {
	user: user,
	isLoading: false,
	isError: false,
	message: '',
}

// login
export const signIn = createAsyncThunk(
	'auth/signIn',
	async (user, thunkAPI) => {
		try {
			return await login(user)
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()
			return thunkAPI.rejectWithValue(message)
		}
	}
)

// slice
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = null
			localStorage.removeItem('user')
		},
		reset: (state) => {
			state.isLoading = false
			state.isErrored = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.isLoading = true
				state.isErrored = false
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.isLoading = false
				state.isErrored = false
				state.user = action.payload
				localStorage.setItem('user', JSON.stringify(action.payload))
			})
			.addCase(signIn.rejected, (state, action) => {
				state.isLoading = false
				state.isErrored = true
				state.message = action.payload
			})
	},
})

// export actions
export const { logout, reset } = authSlice.actions

// export reducer
export default authSlice.reducer
