const navItems = [
	{
		id: 1,
		name: 'home',
		path: '#home',
	},
	{
		id: 2,
		name: 'ourWork',
		path: '#our-work',
	},
	{
		id: 3,
		name: 'howWeWork',
		path: '#how-we-work',
	},
	{
		id: 4,
		name: 'FAQ',
		path: '#faq',
	},
	{
		id: 5,
		name: 'booking',
		path: '#booking',
	},
]

export default navItems
