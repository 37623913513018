import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// API
import {
	fetchBookings,
	createBooking,
	deleteBooking,
	disableBooking,
} from './bookingAPI'

// Initial State
const initialState = {
	bookings: [],
	isLoading: false,
	isError: false,
	message: '',
}

// getBookings
export const getBookings = createAsyncThunk(
	'booking/getBookings',
	async (_, thunkAPI) => {
		try {
			const bookings = await fetchBookings()
			return bookings
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()

			return thunkAPI.rejectWithValue(message)
		}
	}
)

// createBooking
export const createNewBooking = createAsyncThunk(
	'booking/createNewBooking',
	async (booking, thunkAPI) => {
		try {
			const newBooking = await createBooking(booking)
			return newBooking
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()

			return thunkAPI.rejectWithValue(message)
		}
	}
)

// deleteBooking
export const removeBooking = createAsyncThunk(
	'booking/removeBooking',
	async (bookingId, thunkAPI) => {
		try {
			const deletedBooking = await deleteBooking(bookingId)
			return deletedBooking
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()

			return thunkAPI.rejectWithValue(message)
		}
	}
)

// disableBooking
export const disableBook = createAsyncThunk(
	'booking/disableBook',
	async (booking, thunkAPI) => {
		try {
			const disabledBooking = await disableBooking(booking)
			return disabledBooking
		} catch (error) {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString()

			return thunkAPI.rejectWithValue(message)
		}
	}
)

// Slice
const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		resetBooking: (state) => {
			state.isLoading = false
			state.isError = false
			state.message = ''
		},
	},
	extraReducers: (builder) => {
		builder
			// getBookings
			.addCase(getBookings.pending, (state) => {
				state.isLoading = true
				state.isError = false
			})
			.addCase(getBookings.fulfilled, (state, action) => {
				state.isLoading = false
				state.isError = false
				state.bookings = action.payload
			})
			.addCase(getBookings.rejected, (state, action) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
			// createNewBooking
			.addCase(createNewBooking.pending, (state) => {
				state.isLoading = true
				state.isError = false
			})
			.addCase(createNewBooking.fulfilled, (state, action) => {
				state.isLoading = false
				state.isError = false
				state.bookings.push(action.payload)
			})
			.addCase(createNewBooking.rejected, (state, action) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
			// removeBooking
			.addCase(removeBooking.pending, (state) => {
				state.isLoading = true
				state.isError = false
			})
			.addCase(removeBooking.fulfilled, (state, action) => {
				state.isLoading = false
				state.isError = false
				state.bookings = state.bookings.filter(
					(booking) => booking._id !== action.payload._id
				)
			})
			.addCase(removeBooking.rejected, (state, action) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
			// disableBook
			.addCase(disableBook.pending, (state) => {
				state.isLoading = true
				state.isError = false
			})
			.addCase(disableBook.fulfilled, (state, action) => {
				state.isLoading = false
				state.isError = false
				state.bookings.push(action.payload)
			})
			.addCase(disableBook.rejected, (state, action) => {
				state.isLoading = false
				state.isError = true
				state.message = action.payload
			})
	},
})

export const { resetBooking } = bookingSlice.actions

export default bookingSlice.reducer
