// react
import { useState, useEffect } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux'

// react-router
import { useNavigate } from 'react-router-dom'

// mui
import {
	Box,
	Button,
	CircularProgress,
	TextField,
	Stack,
	Alert,
} from '@mui/material'

// assets
import office from '../assets/images/office.jpg'

// actions
import { signIn, reset } from '../features/auth/authSlice'

const AuthScreen = () => {
	// redux
	const dispatch = useDispatch()

	// react-router
	const navigate = useNavigate()

	// form state
	const [form, setForm] = useState({
		email: '',
		password: '',
	})

	const { isLoading, isError, message, user } = useSelector(
		(state) => state.auth
	)

	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		dispatch(signIn(form))
	}

	useEffect(() => {
		if (user) {
			navigate('/admin')
		}

		return () => {
			dispatch(reset())
		}
	}, [user, navigate, dispatch])

	return (
		<Box display='flex' bgcolor='#D3FFE8' height='100vh'>
			<Stack
				flexDirection={{ xs: 'column', md: 'row' }}
				alignItems='center'
				justifyContent='center'
				width='100%'
			>
				<Stack
					spacing={2}
					alignItems='center'
					justifyContent='center'
					width={{ xs: '100%', md: '100%' }}
					height={{ xs: '100%', md: '100vh' }}
				>
					<Stack spacing={3} alignItems='center' justifyContent='center' width='50%'>
						<Box
							alt='rixa logo'
							component='img'
							src='/images/rixa.png'
							width='70px'
						/>
						<TextField
							name='email'
							value={form.email}
							onChange={handleChange}
							fullWidth
							label='Email'
							type='email'
						/>
						<TextField
							name='password'
							value={form.password}
							onChange={handleChange}
							fullWidth
							label='Password'
							type='password'
						/>
						{isLoading ? (
							<CircularProgress />
						) : isError ? (
							<Alert>{message}</Alert>
						) : (
							<Button onClick={handleSubmit} fullWidth variant='contained'>
								Login
							</Button>
						)}
						{message && (
							<Alert sx={{ width: '100%' }} severity='error'>
								{message}
							</Alert>
						)}
					</Stack>
				</Stack>
				<Box
					height={{ xs: '100%', md: '100vh' }}
					component='img'
					src={office}
					sx={{
						objectFit: 'cover',
						objectPosition: 'center',
						display: { xs: 'none', md: 'block' },
					}}
				/>
			</Stack>
		</Box>
	)
}

export default AuthScreen
