// mui
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

const TimeButtons = ({ toggleButton, handleToggleButton, bookings, value }) => {
	// check if time is booked
	const isBooked = (time) => {
		return bookings.some((booking) => {
			return booking.date === value.format('DD/MM/YYYY') && booking.time === time
		})
	}

	const morningTime = ['10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM']
	const eveningTime = ['4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM']

	return (
		<>
			<ToggleButtonGroup
				value={toggleButton}
				exclusive
				onChange={handleToggleButton}
				fullWidth
				sx={{
					'& .MuiToggleButton-root': {
						'&.Mui-selected': {
							backgroundColor: 'primary.main',
							color: '#fff',
						},
						'&:hover': {
							backgroundColor: 'primary.main',
							color: '#fff',
						},
					},
				}}
			>
				{morningTime.map((time) => (
					<ToggleButton key={time} value={time} disabled>
						<Typography>{time}</Typography>
					</ToggleButton>
				))}
			</ToggleButtonGroup>
			<ToggleButtonGroup
				value={toggleButton}
				exclusive
				onChange={handleToggleButton}
				fullWidth
				sx={{
					'& .MuiToggleButton-root': {
						'&.Mui-selected': {
							backgroundColor: 'primary.main',
							color: '#fff',
						},
						'&:hover': {
							backgroundColor: 'primary.main',
							color: '#fff',
						},
					},
				}}
			>
				{eveningTime.map((time) => (
					<ToggleButton key={time} value={time} disabled={isBooked(time)}>
						<Typography>{time}</Typography>
					</ToggleButton>
				))}
			</ToggleButtonGroup>
		</>
	)
}

export default TimeButtons
