// mui
import { Snackbar } from '@mui/material'

const AlertMessage = ({
	openAlertMessage,
	setOpenAlertMessage,
	alertMessage,
}) => {
	return (
		<>
			<Snackbar
				onClose={() => setOpenAlertMessage(false)}
				open={openAlertMessage}
				autoHideDuration={6000}
				message={alertMessage}
			/>
		</>
	)
}

export default AlertMessage
