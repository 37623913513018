import instance from '../instance'

// get all bookings
export const fetchBookings = async () => {
	const { data } = await instance.get('/bookings')

	return data
}

// create a booking
export const createBooking = async (booking) => {
	const { data } = await instance.post('/bookings', booking)

	return data
}

// delete a booking
export const deleteBooking = async (bookingId) => {
	const { data } = await instance.delete(`/bookings/${bookingId}`)

	return data
}

// disable a booking
export const disableBooking = async (booking) => {
	const { data } = await instance.post('/bookings/disable', booking)

	return data
}

const bookingAPI = {
	fetchBookings,
	createBooking,
	deleteBooking,
	disableBooking,
}

export default bookingAPI
