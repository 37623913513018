// react
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'

// react-redux
import { Provider } from 'react-redux'

// redux
import { store } from './app/store'

// react-router
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// styles
import './index.css'

// components
import App from './App'

// screens
import AuthScreen from './screens/AuthScreen'
import AdminScreen from './screens/AdminScreen'
import BookingScreen from './screens/BookingScreen'

// mui
import {
	Box,
	createTheme,
	CssBaseline,
	CircularProgress,
	responsiveFontSizes,
	ThemeProvider,
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// i18n
import './i18n'

// theme
let theme = createTheme({
	palette: {
		primary: {
			main: '#00AA55',
		},
		secondary: {
			main: '#2D6CC0',
		},
	},
	typography: {
		fontFamily: ['Roboto', 'Noto Sans Arabic', 'sans-serif'].join(','),
	},
})

theme = responsiveFontSizes(theme)

const fallbackBox = (
	<Box
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
		}}
	>
		<CircularProgress />
	</Box>
)

// router
const router = createBrowserRouter([
	{ path: '/', element: <App /> },
	{ path: '/auth', element: <AuthScreen /> },
	{ path: '/admin', element: <AdminScreen /> },
	{ path: '/booking', element: <BookingScreen /> },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Suspense fallback={fallbackBox}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<RouterProvider router={router} />
					</LocalizationProvider>
				</Suspense>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>
)
