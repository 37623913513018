import instance from '../instance'

export const login = async (user) => {
	const { data } = await instance.post('/auth/login', user)

	return data
}

const authAPI = {
	login,
}

export default authAPI
