// react
import { useEffect, useState } from 'react'

// react router
import { Link } from 'react-router-dom'

// i18n
import { useTranslation } from 'react-i18next'

// mui
import {
	Box,
	Container,
	Typography,
	AppBar,
	Toolbar,
	ListItem,
	ListItemText,
	ListItemButton,
	Button,
} from '@mui/material'

// lngs
const lngs = {
	en: { nativeName: 'English', dir: 'ltr' },
	ar: { nativeName: 'Arabic', dir: 'rtl' },
}

const Header = () => {
	// i18n
	const { i18n } = useTranslation()

	// current language states
	const [currentLng, setCurrentLng] = useState(i18n.language)

	// handle language change
	const handleLanguageChange = (lng) => {
		i18n.changeLanguage(lng)
		setCurrentLng(lng)
	}

	// set document direction and language
	useEffect(() => {
		document.documentElement.dir = lngs[currentLng].dir
		document.documentElement.lang = currentLng
	}, [currentLng])

	return (
		<AppBar position='sticky'>
			<Container>
				{/* desktop menu */}
				<Toolbar disableGutters>
					<Box
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							flexGrow: 1,
							color: 'inherit',
							fontWeight: '600',
						}}
					>
						<Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>
							<img src='images/rixaLogoWhite.png' alt='logo' width={60} />
						</Link>
					</Box>

					<Box
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							color: 'inherit',
							fontWeight: '600',
						}}
					>
						<Link style={{ textDecoration: 'none', color: 'inherit' }} to='/'>
							<img src='images/rixaLogoWhite.png' alt='logo' width={50} />
						</Link>
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<ListItem disablePadding>
							<ListItemButton
								onClick={() => handleLanguageChange(currentLng === 'en' ? 'ar' : 'en')}
							>
								<ListItemText
									primary={
										<Typography variant='h6'>
											{currentLng === 'en' ? 'العربية' : 'English'}
										</Typography>
									}
								/>
							</ListItemButton>
						</ListItem>
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
						<Button
							onClick={() => handleLanguageChange(currentLng === 'en' ? 'ar' : 'en')}
							sx={{ my: 2, color: 'inherit', display: 'block' }}
						>
							{currentLng === 'en' ? 'العربية' : 'English'}
						</Button>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)
}

export default Header
