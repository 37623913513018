// mui
import { Box, Typography, Stack, Container } from '@mui/material'

// components
import AccordionStyled from './AccordionStyled'

// i18n
import { useTranslation } from 'react-i18next'

const FrequentlyAskedQuestions = () => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box id='faq' py={7} bgcolor='#D3FFE9'>
			<Container>
				{/* start home stack */}
				<Stack spacing={7}>
					{/* start text stack */}
					<Stack textAlign='center' alignItems='center' spacing={1}>
						<Typography variant='h4' sx={{ fontWeight: '600' }}>
							{t('faq.heading')}
						</Typography>
						<Typography maxWidth='450px' variant='body1' color='text.secondary'>
							{t('faq.subheading')}
						</Typography>
					</Stack>
					{/* end text stack */}
					{/* start accordion stack */}
					<Stack>
						<AccordionStyled
							title={t('faq.questions.questionOne.question')}
							body={t('faq.questions.questionOne.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionTwo.question')}
							body={t('faq.questions.questionTwo.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionThree.question')}
							body={t('faq.questions.questionThree.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionFour.question')}
							body={t('faq.questions.questionFour.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionFive.question')}
							body={t('faq.questions.questionFive.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionSix.question')}
							body={t('faq.questions.questionSix.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionSeven.question')}
							body={t('faq.questions.questionSeven.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionEight.question')}
							body={t('faq.questions.questionEight.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionNine.question')}
							body={t('faq.questions.questionNine.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionTen.question')}
							body={t('faq.questions.questionTen.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionEleven.question')}
							body={t('faq.questions.questionEleven.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionTwelve.question')}
							body={t('faq.questions.questionTwelve.answer')}
						/>
						<AccordionStyled
							title={t('faq.questions.questionThirteen.question')}
							body={t('faq.questions.questionThirteen.answer')}
						/>
					</Stack>
					{/* end accordion stack */}
				</Stack>
				{/* end home stack */}
			</Container>
		</Box>
	)
}

export default FrequentlyAskedQuestions
