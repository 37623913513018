// mui
import { Box, Container, Stack, Typography } from '@mui/material'

// components
import WorkProcess from './WorkProcess'

// lottie files
import wireFrameLottie from '../assets/lottieFiles/wireFrameLottie.json'
import designLottie from '../assets/lottieFiles/designLottie.json'
import developmentLottie from '../assets/lottieFiles/developmentLottie.json'

// i18n
import { useTranslation } from 'react-i18next'

const HowWeWork = () => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box id='how-we-work' bgcolor='#FFFBE6' py={7}>
			<Container>
				{/* start home stack */}
				<Stack spacing={7}>
					{/* start text stack */}
					<Stack textAlign='center' alignItems='center' spacing={1}>
						<Typography variant='h4' sx={{ fontWeight: '600' }}>
							{t('howWeWork.heading')}
						</Typography>
						<Typography maxWidth='450px' variant='body1' color='text.secondary'>
							{t('howWeWork.subheading')}
						</Typography>
					</Stack>
					{/* end text stack */}
				</Stack>
				{/* end home stack */}
				{/* start work stack */}
				<Stack
					alignItems='center'
					justifyContent='space-between'
					direction={{ xs: 'column', md: 'row' }}
					spacing={{ xs: 7, md: 0 }}
				>
					<WorkProcess
						lottie={wireFrameLottie}
						step='1'
						title={t('howWeWork.steps.stepOne.title')}
						body={t('howWeWork.steps.stepOne.body')}
					/>
					<WorkProcess
						lottie={designLottie}
						step='2'
						title={t('howWeWork.steps.stepTwo.title')}
						body={t('howWeWork.steps.stepTwo.body')}
					/>
					<WorkProcess
						lottie={developmentLottie}
						step='3'
						title={t('howWeWork.steps.stepThree.title')}
						body={t('howWeWork.steps.stepThree.body')}
					/>
				</Stack>
			</Container>
		</Box>
	)
}

export default HowWeWork
