// datePicker
import { StaticDatePicker } from '@mui/x-date-pickers'

const Calender = ({ date, setDate }) => {
	// make a function to disable weekends
	const isWeekend = (date) => {
		const day = date.toDate().getDay()
		return day === 5 || day === 6
	}

	return (
		<StaticDatePicker
			displayStaticWrapperAs='desktop'
			sx={{ borderRadius: '0.5rem', direction: 'ltr' }}
			shouldDisableDate={isWeekend}
			disablePast
			views={['day']}
			value={date}
			onChange={(newDate) => setDate(newDate)}
		/>
	)
}

export default Calender
