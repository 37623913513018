// react
import { useEffect } from 'react'

// components
import Header from './components/Header'
import Hero from './components/Hero'
import OurWork from './components/OurWork'
import HowWeWork from './components/HowWeWork'
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions'
import Footer from './components/Footer'
import ActionButton from './components/ActionButton'
import Booking from './components/Booking'

// google analytics
import ReactGA from 'react-ga4'

function App() {
	useEffect(() => {
		ReactGA.initialize('G-H0P5XQ2KQ0')
	}, [])

	return (
		<>
			<Header />
			<Hero />
			<OurWork />
			<HowWeWork />
			<FrequentlyAskedQuestions />
			<Booking />
			<Footer />
			<ActionButton />
		</>
	)
}

export default App
