// react
import { useState, useEffect } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux'

// mui
import {
	Alert,
	Box,
	Container,
	CircularProgress,
	Typography,
	Stack,
	Stepper,
	Step,
	StepLabel,
	Button,
	Paper,
} from '@mui/material'

// components
import Calender from './Calender'
import TimeButtons from './TimeButtons'
import BookingForm from './BookingForm'
import AlertMessage from './AlertMessage'

// actions
import { createNewBooking, getBookings } from '../features/booking/bookingSlice'

// i18n
import { useTranslation } from 'react-i18next'

const Booking = () => {
	const dispatch = useDispatch()

	// i18n
	const { t } = useTranslation()

	const { bookings, isError, isLoading, message } = useSelector(
		(state) => state.booking
	)

	// stepper state
	const [activeStep, setActiveStep] = useState(0)

	// toggle button state
	const [toggleButton, setToggleButton] = useState('')

	// date state
	const [date, setDate] = useState('')

	// alert message state
	const [openAlertMessage, setOpenAlertMessage] = useState(false)

	// alert message
	const [alertMessage, setAlertMessage] = useState('')

	// form state
	const [form, setForm] = useState({
		name: '',
		email: '',
		phone: '',
		description: '',
	})

	// handle next step
	const handleNext = () => {
		// check if the user has selected a date
		if (activeStep === 0 && date === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.date'))
			return
		}

		// disable next button if the user is booking for today
		if (activeStep === 0 && date.isSame(new Date(), 'day')) {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.todayBooked'))
			return
		}

		// check if the user has selected a time
		if (activeStep === 1 && toggleButton === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.time'))
			return
		}

		// check if the user has filled the name field
		if (activeStep === 2 && form.name === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.name'))
			return
		}

		// check if the user has filled the email field
		if (activeStep === 2 && form.email === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.email'))
			return
		}

		// check if the user has filled the email field with a valid email
		const emailRegex = /\S+@\S+\.\S+/
		if (activeStep === 2 && !emailRegex.test(form.email)) {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.emailInvalid'))
			return
		}

		// check if the user has filled the phone field
		if (activeStep === 2 && form.phone === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.phone'))
			return
		}

		// check if the user has filled the phone field with a valid phone number
		const phoneRegex = /^\d{8}$/
		if (activeStep === 2 && !phoneRegex.test(form.phone)) {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.phoneInvalid'))
			return
		}

		// check if the user has filled the project description field
		if (activeStep === 2 && form.description === '') {
			setOpenAlertMessage(true)
			setAlertMessage(t('booking.messages.projectDescription'))
			return
		}

		// submit the form
		if (activeStep === 2) {
			const newBooking = {
				date: date.format('DD/MM/YYYY'),
				time: toggleButton,
				name: form.name,
				email: form.email,
				phone: form.phone,
				description: form.description,
			}

			dispatch(createNewBooking(newBooking))
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1)
	}

	// handle back step
	const handleBack = () => {
		setActiveStep(0)
		setToggleButton('')
		setDate('')
	}

	// handle toggle button
	const handleToggleButton = (event, newTime) => {
		setToggleButton(newTime)
	}

	// handle form change
	const onChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	// on component mount
	useEffect(() => {
		if (bookings.length === 0) {
			dispatch(getBookings())
		}
	}, [date, dispatch, bookings.length])

	return (
		<Box id='booking' bgcolor='#FFFBE6' py={7} minHeight='100vh'>
			<Container>
				<Stack spacing={7}>
					{/* start text stack */}
					<Stack textAlign='center' alignItems='center' spacing={1}>
						<Typography variant='h4' sx={{ fontWeight: '600' }}>
							{t('booking.heading')}
						</Typography>
						<Typography maxWidth='450px' variant='body1' color='text.secondary'>
							{t('booking.subheading')}
						</Typography>
					</Stack>

					{/* end text stack */}

					{/* start calender and location stack */}
					<Stack
						justifyContent='space-between'
						alignItems='center'
						gap={3}
						direction={{ xs: 'column', sm: 'column', md: 'row' }}
					>
						{/* start calender with and attachment */}
						<Stack spacing={3} width={{ xs: '100%', sm: '100%', md: '50%' }}>
							<Stack spacing={2}>
								<Stepper
									style={{ direction: 'ltr' }}
									activeStep={activeStep}
									alternativeLabel
								>
									<Step key='1'>
										<StepLabel>{t('booking.steeper.stepOne')}</StepLabel>
									</Step>
									<Step key='2'>
										<StepLabel>{t('booking.steeper.stepTwo')}</StepLabel>
									</Step>
									<Step key='3'>
										<StepLabel>{t('booking.steeper.stepThree')}</StepLabel>
									</Step>
									<Step key='4'>
										<StepLabel>{t('booking.steeper.stepFour')}</StepLabel>
									</Step>
								</Stepper>
								{/* test test test */}
							</Stack>
							{/* start date */}
							{activeStep === 0 && (
								<Stack spacing={3}>
									<Stack spacing={1}>
										<Typography variant='h5' fontWeight='600'>
											{t('booking.dateStep.heading')}
										</Typography>
										<Typography variant='body1' color='text.secondary'>
											{t('booking.dateStep.subheading')}
										</Typography>
									</Stack>
									<Calender date={date} setDate={setDate} />
								</Stack>
							)}
							{/* end date */}
							{/* start time */}
							{activeStep === 1 &&
								(isLoading ? (
									<CircularProgress />
								) : isError ? (
									<Alert severity='error'>{message}</Alert>
								) : (
									<Stack spacing={3}>
										<Stack>
											<Typography variant='h5' fontWeight='600'>
												{t('booking.timeStep.heading')}
											</Typography>
											<Typography variant='body1' color='text.secondary'>
												{t('booking.timeStep.subheading')}
											</Typography>
										</Stack>
										{/* show date */}
										<Alert
											severity='success'
											sx={{
												'& .MuiAlert-icon': {
													color: '#fff',
												},
												background: '#00AA55',
												color: '#fff',
											}}
										>
											{date.format('dddd, DD/MM/YYYY')}
										</Alert>
										<TimeButtons
											value={date}
											toggleButton={toggleButton}
											handleToggleButton={handleToggleButton}
											bookings={bookings}
										/>
									</Stack>
								))}
							{/* start form */}
							{activeStep === 2 && (
								<Stack spacing={3}>
									<Stack>
										<Typography variant='h5' fontWeight='600'>
											{t('booking.detailsStep.heading')}
										</Typography>
										<Typography variant='body1' color='text.secondary'>
											{t('booking.detailsStep.subheading')}
										</Typography>
									</Stack>
									{/* show date and time */}
									<Alert
										severity='success'
										sx={{
											'& .MuiAlert-icon': {
												color: '#fff',
											},
											background: '#00AA55',
											color: '#fff',
										}}
									>
										{date.format('dddd, DD/MM/YYYY')} - {toggleButton}
									</Alert>
									<BookingForm form={form} onChange={onChange} />
								</Stack>
							)}
							{/* end form */}
							{/* start confirm */}
							{activeStep === 3 && (
								<Stack spacing={3}>
									{isLoading ? (
										<CircularProgress />
									) : isError ? (
										<Alert severity='error'>{message}</Alert>
									) : (
										<Stack spacing={2}>
											<Stack>
												<Typography variant='h5' fontWeight='600'>
													{t('booking.thankYouStep.heading')}
												</Typography>
												<Typography variant='body1' color='text.secondary'>
													{t('booking.thankYouStep.subheading')}
												</Typography>
											</Stack>
											<Paper elevation={3} sx={{ padding: '2rem', borderRadius: '10px' }}>
												<Stack>
													<Stack
														direction='row'
														sx={{ borderBottom: '1px solid #ccc', pb: '1.5rem' }}
													>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.name')}
														</Typography>
														<Typography variant='body1'>{form.name}</Typography>
													</Stack>
													<Stack
														direction='row'
														sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
													>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.email')}
														</Typography>
														<Typography variant='body1'>{form.email}</Typography>
													</Stack>
													<Stack
														direction='row'
														sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
													>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.phone')}
														</Typography>
														<Typography variant='body1'>{form.phone}</Typography>
													</Stack>
													<Stack
														direction='row'
														sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
													>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.date')}
														</Typography>
														<Typography variant='body1'>
															{date.format('dddd, DD/MM/YYYY')}
														</Typography>
													</Stack>
													<Stack
														direction='row'
														sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
													>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.time')}
														</Typography>
														<Typography variant='body1'>{toggleButton}</Typography>
													</Stack>
													<Stack direction='row' sx={{ pt: '1.5rem' }}>
														<Typography fontWeight='600' minWidth='130px' variant='body1'>
															{t('booking.thankYouStep.details.description')}
														</Typography>
														<Typography variant='body1'>{form.description}</Typography>
													</Stack>
												</Stack>
											</Paper>
										</Stack>
									)}
								</Stack>
							)}
							{/* end confirm */}
							{/* buttons */}
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								gap={2}
								// reverse the buttons order on mobile
								sx={{
									'& button:nth-of-type(1)': {
										order: { xs: 2, sm: 1 },
									},
									'& button:nth-of-type(2)': {
										order: { xs: 1, sm: 2 },
									},
								}}
							>
								{activeStep > 0 && activeStep < 3 && (
									<Button
										variant='outlined'
										color='primary'
										fullWidth
										onClick={handleBack}
									>
										{t('booking.form.buttons.back')}
									</Button>
								)}
								{activeStep < 3 && (
									<Button
										variant='contained'
										color='primary'
										fullWidth
										onClick={handleNext}
									>
										{t('booking.form.buttons.next')}
									</Button>
								)}
							</Stack>
						</Stack>
						{/* end calender with and attachment */}

						{/* start location */}
						<Stack width={{ xs: '100%', sm: '100%', md: '50%' }}>
							<iframe
								title='location'
								src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3477.0019761432186!2d47.97790647522871!3d29.370231350200147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf85856834be4d%3A0x256ff3624e07152b!2sAl%20Tujjar%20Tower!5e0!3m2!1sen!2skw!4v1684573740229!5m2!1sen!2skw'
								style={{
									width: '100%',
									height: '40rem',
									border: 0,
								}}
								allowFullScreen=''
								loading='lazy'
								referrerPolicy='no-referrer-when-downgrade'
							></iframe>
						</Stack>
					</Stack>
					{/* end location */}

					{/* start home stack */}
					{/* start left side */}
				</Stack>
				{/* end home stack */}

				{/* alert message */}
				<AlertMessage
					openAlertMessage={openAlertMessage}
					setOpenAlertMessage={setOpenAlertMessage}
					alertMessage={alertMessage}
				/>
			</Container>
		</Box>
	)
}

export default Booking
