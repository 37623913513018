// mui
import { Box, Typography, Stack, Link } from '@mui/material'

// i18n
import { useTranslation } from 'react-i18next'

const Work = ({ img, title, body, link }) => {
	// i18n
	const { t } = useTranslation()

	return (
		<Stack
			justifyContent='space-between'
			alignItems='center'
			maxWidth='350px'
			minHeight='300px'
			spacing={5}
		>
			<Box width='250px' src={img} component='img' />
			<Stack spacing={1}>
				<Typography textAlign='center' variant='h5' fontWeight={500}>
					{title}
				</Typography>
				<Typography textAlign='center' variant='body1' color='text.secondary'>
					{body}
				</Typography>
				<Link href={link} target='_blank' rel='noreferrer'>
					<Typography textAlign='center' variant='body1' color='primary'>
						{t('ourWork.visitWebsite')}
					</Typography>
				</Link>
			</Stack>
		</Stack>
	)
}

export default Work
