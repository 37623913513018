// mui
import { Box } from '@mui/material'

// components
import Booking from '../components/Booking'
import BookingHeader from '../components/BookingHeader'

const BookingScreen = () => {
	return (
		<Box>
			<BookingHeader />
			<Booking />
		</Box>
	)
}

export default BookingScreen
