// react
import { useState } from 'react'

//  react router
import { Link } from 'react-router-dom'

// redux
import { useDispatch } from 'react-redux'

// mui
import { Box, Button, Dialog, Paper, Stack, Typography } from '@mui/material'

// mui icons
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DeleteIcon from '@mui/icons-material/Delete'

// daysjs
import dayjs from 'dayjs'

// actions
import { removeBooking } from '../features/booking/bookingSlice'

const BookingItem = ({
	id: _id,
	name,
	email,
	phone,
	date,
	time,
	description,
	createdAt,
	number,
}) => {
	// dispatch
	const dispatch = useDispatch()

	// dialog state
	const [open, setOpen] = useState(false)

	const handleDelete = (id) => {
		dispatch(removeBooking(id))
		setOpen(false)
	}

	return (
		<Paper elevation={3} sx={{ padding: '2rem', borderRadius: '10px' }}>
			<Stack>
				<Stack direction='row' justifyContent='space-between'>
					<Box>
						<Typography color='text.secondary' variant='caption'>
							Booking #{number}
						</Typography>
					</Box>
					<Stack direction='row' gap={1}>
						<Stack direction='row' alignItems='center' spacing={1}>
							<CalendarTodayIcon sx={{ color: 'text.secondary', fontSize: '12px' }} />
							<Typography color='text.secondary' variant='caption'>
								{dayjs(createdAt).format('DD/MM/YYYY')}
							</Typography>
						</Stack>
						<Stack direction='row' alignItems='center' spacing={1}>
							<AccessTimeIcon sx={{ color: 'text.secondary', fontSize: '12px' }} />
							<Typography color='text.secondary' variant='caption'>
								{dayjs(createdAt).format('h:m A')}
							</Typography>
						</Stack>
					</Stack>
				</Stack>
				<Stack
					direction='row'
					sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
				>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Name
					</Typography>
					<Typography variant='body1'>{name}</Typography>
				</Stack>
				<Stack
					direction='row'
					sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
				>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Email
					</Typography>
					<Typography variant='body1'>
						<Link to={`mailto:${email}`}>{email}</Link>
					</Typography>
				</Stack>
				<Stack
					direction='row'
					sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
				>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Phone
					</Typography>
					<Typography variant='body1'>
						<Link to={`tel:${phone}`}>{phone}</Link>
					</Typography>
				</Stack>
				<Stack
					direction='row'
					sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
				>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Date
					</Typography>
					<Typography variant='body1'>{date}</Typography>
				</Stack>
				<Stack
					direction='row'
					sx={{ borderBottom: '1px solid #ccc', py: '1.5rem' }}
				>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Time
					</Typography>
					<Typography variant='body1'>{time}</Typography>
				</Stack>
				<Stack direction='row' sx={{ pt: '1.5rem' }}>
					<Typography fontWeight='600' minWidth='100px' variant='body1'>
						Description
					</Typography>
					<Typography variant='body1'>{description}</Typography>
				</Stack>
				<Stack direction='row' sx={{ pt: '3rem' }}>
					<Button
						fullWidth
						variant='contained'
						color='error'
						startIcon={<DeleteIcon />}
						onClick={() => setOpen(true)}
					>
						Delete Booking
					</Button>
				</Stack>
			</Stack>

			<Dialog open={open} onClose={() => setOpen(false)}>
				<Box sx={{ p: '2rem' }}>
					<Typography variant='h5' fontWeight='600' mb={2}>
						Are you sure you want to delete this booking?
					</Typography>
					<Stack direction='row' spacing={2}>
						<Button
							fullWidth
							variant='contained'
							color='primary'
							onClick={() => setOpen(false)}
						>
							Cancel
						</Button>
						<Button
							fullWidth
							variant='contained'
							color='error'
							onClick={() => handleDelete(_id)}
						>
							Delete
						</Button>
					</Stack>
				</Box>
			</Dialog>
		</Paper>
	)
}

export default BookingItem
