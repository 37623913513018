// mui
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material'

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const AccordionStyled = ({ title, body }) => {
	return (
		<Accordion
			sx={{
				backgroundColor: '#FFFBE6',
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='panel1a-content'
				id='panel1a-header'
			>
				<Typography variant='h6'>{title}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Typography>{body}</Typography>
			</AccordionDetails>
		</Accordion>
	)
}

export default AccordionStyled
