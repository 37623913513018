// mui
import { Box, Typography, Stack, Container } from '@mui/material'

// components
import Work from './Work'

// assets/images
import work1 from '../assets/images/work1.png'
import work2 from '../assets/images/work2.png'
import work3 from '../assets/images/work3.png'
import work4 from '../assets/images/work4.png'

// i18n
import { useTranslation } from 'react-i18next'

const OurWork = () => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box id='our-work' py={7} bgcolor='#D3FFE9'>
			<Container>
				{/* start home stack */}
				<Stack spacing={7}>
					{/* start text stack */}
					<Stack textAlign='center' alignItems='center' spacing={1}>
						<Typography variant='h4' sx={{ fontWeight: '600' }}>
							{t('ourWork.heading')}
						</Typography>
						<Typography maxWidth='450px' variant='body1' color='text.secondary'>
							{t('ourWork.subheading')}
						</Typography>
					</Stack>
					{/* end text stack */}

					{/* start work stack */}
					<Stack
						alignItems='center'
						justifyContent='space-between'
						direction={{ xs: 'column', md: 'row' }}
						spacing={{ xs: 7, md: 0 }}
					>
						<Work
							img={work1}
							title={t('ourWork.thePhotographer.heading')}
							body={t('ourWork.thePhotographer.subheading')}
							link='https://the-photographer-portfolio.netlify.app/'
						/>
						<Work
							img={work2}
							title={t('ourWork.theFastDelivery.heading')}
							body={t('ourWork.theFastDelivery.subheading')}
							link='https://the-fast-delivery.netlify.app/'
						/>
						<Work
							img={work3}
							title={t('ourWork.aluminumFactory.heading')}
							body={t('ourWork.aluminumFactory.subheading')}
							link='https://alhajiri.vercel.app/'
						/>
						<Work
							img={work4}
							title={t('ourWork.thecompensationlawyer.heading')}
							body={t('ourWork.thecompensationlawyer.subheading')}
							link='https://thecompensationlawyer.com/'
						/>
					</Stack>
				</Stack>
				{/* end home stack */}
			</Container>
		</Box>
	)
}

export default OurWork
