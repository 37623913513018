// mui
import { Stack, Typography } from '@mui/material'

// Lottie
import Lottie from 'lottie-react'

const WorkProcess = ({ lottie, step, title, body }) => {
	return (
		<Stack justifyContent='space-between' maxWidth='350px' minHeight='300px'>
			<Lottie animationData={lottie} style={{ width: '300px', height: 'auto' }} />
			<Stack spacing={1}>
				<Typography textAlign='center' variant='body1' color='primary'>
					{step}
				</Typography>
				<Typography textAlign='center' variant='h5' fontWeight={500}>
					{title}
				</Typography>
				<Typography textAlign='center' variant='body1' color='text.secondary'>
					{body}
				</Typography>
			</Stack>
		</Stack>
	)
}

export default WorkProcess
