// react
import { useState, useEffect } from 'react'

// redux
import { useDispatch, useSelector } from 'react-redux'

// react-router
import { useNavigate } from 'react-router-dom'

// mui
import {
	Box,
	Button,
	Container,
	CircularProgress,
	Alert,
	Stack,
	Divider,
	Typography,
} from '@mui/material'

// icons
import LogoutIcon from '@mui/icons-material/Logout'

// actions
import { logout } from '../features/auth/authSlice'
import { getBookings, disableBook } from '../features/booking/bookingSlice'

// components
import BookingItem from '../components/BookingItem'
import Calender from '../components/Calender'
import TimeButtons from '../components/TimeButtons'

// dayjs
import dayjs from 'dayjs'

const AdminScreen = () => {
	// date state
	const [date, setDate] = useState(dayjs())

	// toggleButton state
	const [toggleButton, setToggleButton] = useState('')

	// handleToggleButton
	const handleToggleButton = (event, newToggleButton) => {
		setToggleButton(newToggleButton)
	}

	// redux
	const dispatch = useDispatch()

	// react-router
	const navigate = useNavigate()

	// redux state
	const { user } = useSelector((state) => state.auth)
	const { isError, isLoading, message, bookings } = useSelector(
		(state) => state.booking
	)

	// handleSubmit
	const handleSubmit = (event) => {
		event.preventDefault()
		dispatch(
			disableBook({
				date: date.format('DD/MM/YYYY'),
				time: toggleButton,
			})
		)
	}

	const handleLogout = () => {
		dispatch(logout())
		navigate('/auth')
	}

	useEffect(() => {
		if (!user) {
			navigate('/auth')
		}

		if (!bookings.length) {
			dispatch(getBookings())
		}
	}, [user, navigate, dispatch, bookings.length])

	return (
		<Box py={4} bgcolor='#D3FFE9' display='flex' minHeight='100vh'>
			<Container>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					mb={7}
				>
					<Stack direction='row' spacing={2} alignItems='center' mb={2}>
						<img src='/images/rixaLogoGreen.png' alt='logo' width={70} />
					</Stack>
					<Button
						variant='outlined'
						color='primary'
						startIcon={<LogoutIcon />}
						onClick={handleLogout}
					>
						Logout
					</Button>
				</Box>

				{isLoading ? (
					<CircularProgress />
				) : isError ? (
					<Alert severity='error'>{message}</Alert>
				) : (
					<Stack spacing={2}>
						<Stack>
							<Typography variant='h3'>Disable Booking</Typography>
							<Typography color='text.secondary' variant='body1'>
								Select date and time to disable a booking
							</Typography>
						</Stack>
						<form onSubmit={handleSubmit}>
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
									gap: '1rem',
									alignItems: 'center',
								}}
							>
								<Calender date={date} setDate={setDate} />

								<Stack spacing={2}>
									<TimeButtons
										toggleButton={toggleButton}
										handleToggleButton={handleToggleButton}
										bookings={bookings}
										value={date}
									/>

									<Button
										type='submit'
										variant='contained'
										color='primary'
										fullWidth
										disabled={!toggleButton}
									>
										Disable Booking
									</Button>
								</Stack>
							</Box>
						</form>
						<Divider />
						<Stack>
							<Typography variant='h3'>Bookings List</Typography>
							<Typography color='text.secondary' variant='body1'>
								The cards below shows all the bookings details
							</Typography>
						</Stack>
						<Box
							sx={{
								display: 'grid',
								gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
								gap: '1rem',
							}}
						>
							{bookings.map((booking, index) => (
								<BookingItem
									key={index}
									id={booking._id}
									name={booking.name}
									email={booking.email}
									phone={booking.phone}
									date={booking.date}
									time={booking.time}
									description={booking.description}
									createdAt={booking.createdAt}
									number={index + 1}
								/>
							))}
						</Box>
					</Stack>
				)}
			</Container>
		</Box>
	)
}

export default AdminScreen
