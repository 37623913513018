// mui
import { Box, Button, Container, Stack, Typography } from '@mui/material'

// lottie
import Lottie from 'lottie-react'

// lottie files
import heroLottie from '../assets/lottieFiles/heroLottie.json'

// i18n
import { useTranslation } from 'react-i18next'

const Hero = () => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box id='home' bgcolor='#FFFBE6' py={{ xs: 3, md: 0 }}>
			<Container>
				<Stack direction={{ xs: 'column', md: 'row' }}>
					<Stack width='100%' spacing={5} justifyContent='center'>
						<Stack spacing={1}>
							<Typography variant='body1' color='primary'>
								{t('hero.preHeading')}
							</Typography>
							<Typography variant='h3' fontWeight='600'>
								{t('hero.heading')}
							</Typography>
							<Typography variant='body1' color='text.secondary' maxWidth='500px'>
								{t('hero.subHeading')}
							</Typography>
						</Stack>
						<Stack
							alignItems={{ sm: 'center' }}
							gap={1}
							direction={{ xs: 'column', sm: 'row' }}
						>
							<Button href='#booking' variant='contained' color='secondary'>
								{t('hero.buttons.bookNow')}
							</Button>
							<Button href='#how-we-work' variant='outlined' color='primary'>
								{t('hero.buttons.learnMore')}
							</Button>
							<Typography variant='body1' color='text.secondary'>
								{t('hero.postHeading')}
							</Typography>
						</Stack>
					</Stack>
					<Box width='100%'>
						<Lottie animationData={heroLottie} />
					</Box>
				</Stack>
			</Container>
		</Box>
	)
}

export default Hero
