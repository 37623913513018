// mui
import { Box, TextField } from '@mui/material'

// i18n
import { useTranslation } from 'react-i18next'

const BookingForm = ({ form, onChange }) => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box>
			<TextField
				type='text'
				helperText={t('booking.form.nameHelper')}
				value={form.name}
				name='name'
				onChange={onChange}
				fullWidth
				label={t('booking.form.name')}
				variant='outlined'
				size='small'
				sx={{ mb: 2 }}
			/>
			<TextField
				type='email'
				helperText={t('booking.form.emailHelper')}
				value={form.email}
				name='email'
				onChange={onChange}
				fullWidth
				label={t('booking.form.email')}
				variant='outlined'
				size='small'
				sx={{ mb: 2 }}
			/>
			<TextField
				type='tel'
				helperText={t('booking.form.phoneHelper')}
				value={form.phone}
				name='phone'
				onChange={onChange}
				fullWidth
				label={t('booking.form.phone')}
				variant='outlined'
				size='small'
				sx={{ mb: 2 }}
			/>
			<TextField
				helperText={t('booking.form.projectDescriptionHelper')}
				value={form.description}
				name='description'
				onChange={onChange}
				fullWidth
				label={t('booking.form.projectDescription')}
				variant='outlined'
				size='small'
				sx={{ mb: 2 }}
				multiline
				rows={4}
			/>
		</Box>
	)
}

export default BookingForm
