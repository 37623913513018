// mui
import { Fab } from '@mui/material'

// icons
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

const ActionButton = () => {
	return (
		<Fab
			sx={{ position: 'fixed', bottom: 16, left: 16 }}
			href='https://wa.me/96599769292'
			color='primary'
		>
			<WhatsAppIcon />
		</Fab>
	)
}

export default ActionButton
