// mui
import { Box, Container, Stack, Typography } from '@mui/material'

// i18n
import { useTranslation } from 'react-i18next'

const Footer = () => {
	// i18n
	const { t } = useTranslation()

	return (
		<Box
			sx={{
				py: '1rem',
				backgroundColor: (theme) => theme.palette.primary.main,
				color: (theme) => theme.palette.primary.contrastText,
			}}
		>
			<Container
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<Stack
					spacing={2}
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent='space-between'
				>
					<Stack spacing={1}>
						<Typography variant='h6'>{t('footer.heading')}</Typography>
						<Typography variant='body2'>
							{t('footer.subheading')} © {new Date().getFullYear()}
						</Typography>
					</Stack>
				</Stack>
			</Container>
		</Box>
	)
}

export default Footer
