// react
import { useEffect, useState } from 'react'

// i18n
import { useTranslation } from 'react-i18next'

// mui
import {
	Box,
	Container,
	IconButton,
	Typography,
	AppBar,
	Toolbar,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
	Button,
} from '@mui/material'

// icons
import MenuIcon from '@mui/icons-material/Menu'

// data
import navItems from '../data/navItems'

// lngs
const lngs = {
	en: { nativeName: 'English', dir: 'ltr' },
	ar: { nativeName: 'Arabic', dir: 'rtl' },
}

// drawer width
const drawerWidth = 240

const Header = () => {
	// i18n
	const { t, i18n } = useTranslation()

	// current language states
	const [currentLng, setCurrentLng] = useState(i18n.language)

	// app bar drawer states
	const [mobileOpen, setMobileOpen] = useState(false)

	// handle drawer toggle
	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState)
	}

	// handle language change
	const handleLanguageChange = (lng) => {
		i18n.changeLanguage(lng)
		setCurrentLng(lng)
	}

	// set document direction and language
	useEffect(() => {
		document.documentElement.dir = lngs[currentLng].dir
		document.documentElement.lang = currentLng
	}, [currentLng])

	// mobile menu
	const drawer = (
		<Container onClick={handleDrawerToggle}>
			<ListItem>
				<ListItemText>
					<img width={60} src='/images/rixaLogoGreen.png' alt='logo' />
				</ListItemText>
			</ListItem>
			<Divider />
			<List>
				<ListItem disablePadding>
					<ListItemButton href='#home'>
						<ListItemText
							primary={<Typography variant='h6'>{t('header.home')}</Typography>}
						/>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton href='#our-work'>
						<ListItemText
							primary={<Typography variant='h6'>{t('header.ourWork')}</Typography>}
						/>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton href='#faq'>
						<ListItemText
							primary={<Typography variant='h6'>{t('header.FAQ')}</Typography>}
						/>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton href='#booking'>
						<Button fullWidth variant='contained' color='secondary'>
							<ListItemText
								primary={<Typography variant='h6'>{t('header.booking')}</Typography>}
							/>
						</Button>
					</ListItemButton>
				</ListItem>
				<ListItem disablePadding>
					<ListItemButton
						onClick={() => handleLanguageChange(currentLng === 'en' ? 'ar' : 'en')}
					>
						<ListItemText
							primary={
								<Typography variant='h6'>
									{currentLng === 'en' ? 'العربية' : 'English'}
								</Typography>
							}
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</Container>
	)

	return (
		<AppBar position='sticky'>
			<Container>
				{/* desktop menu */}
				<Toolbar disableGutters>
					<Box
						sx={{
							mr: 2,
							display: { xs: 'none', md: 'flex' },
							flexGrow: 1,
							color: 'inherit',
							fontWeight: '600',
						}}
					>
						<img width={60} src='/images/rixaLogoWhite.png' alt='logo' />
					</Box>

					<Box
						sx={{
							mr: 2,
							display: { xs: 'flex', md: 'none' },
							flexGrow: 1,
							color: 'inherit',
							fontWeight: '600',
						}}
					>
						<img width={50} src='/images/rixaLogoWhite.png' alt='logo' />
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton size='large' onClick={handleDrawerToggle} color='inherit'>
							<MenuIcon />
						</IconButton>
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
						{navItems.map((item) => (
							<Button
								variant={item.name === 'booking' ? 'contained' : 'text'}
								color={item.name === 'booking' ? 'secondary' : 'inherit'}
								href={item.path}
								key={item.id}
								sx={{ my: 2, color: 'inherit', display: 'block', fontWeight: '600' }}
							>
								{t(`header.${item.name}`)}
							</Button>
						))}
						<Button
							onClick={() => handleLanguageChange(currentLng === 'en' ? 'ar' : 'en')}
							sx={{ my: 2, color: 'inherit', display: 'block' }}
						>
							{currentLng === 'en' ? 'العربية' : 'English'}
						</Button>
					</Box>
				</Toolbar>
			</Container>

			<Box component='nav'>
				<Drawer
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					anchor='right'
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
			</Box>
		</AppBar>
	)
}

export default Header
